import { template as template_9bc293e1fefe445a957ae7c41f4d92f5 } from "@ember/template-compiler";
const SidebarSectionMessage = template_9bc293e1fefe445a957ae7c41f4d92f5(`
  <div class="sidebar-section-message-wrapper sidebar-row">
    <div class="sidebar-section-message">
      {{yield}}
    </div>
  </div>
`, {
    eval () {
        return eval(arguments[0]);
    }
});
export default SidebarSectionMessage;
