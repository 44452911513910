import { template as template_e72769f994ad4981a3e53b637e9bb29e } from "@ember/template-compiler";
const FKText = template_e72769f994ad4981a3e53b637e9bb29e(`
  <p class="form-kit-text" ...attributes>
    {{yield}}
  </p>
`, {
    eval () {
        return eval(arguments[0]);
    }
});
export default FKText;
